import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Autocomplete,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DeleteModal from "../../common/DeleteModal";
import AlertDialog from "../../common/AlertDialog";
import { PER_PAGE, ROLES } from "../../helpers/constants";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import { adminAction } from "../../../redux/slices/admin/admins/adminSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "../AdminDashboard/propertyTable.scss";

const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
  },
  { key: 2, id: "name", disablePadding: true, label: "Name" },
  { key: 3, id: "email", disablePadding: true, label: "Email" },
  { key: 4, id: "company_name", disablePadding: true, label: "Company/County" },
  { key: 5, id: "admin", disablePadding: true, label: "Admin" },
  {
    key: 6,
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    sorting: true,
  },
  { key: 7, id: "action", disablePadding: true, label: "Actions" },
];

export default function AdminListTable({ handleEditButton, handleClose }) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [isDeleteAdminModalOpen, setIsDeleteAdminModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isFilterApiCalled, setIsFilterApiCalled] = useState(null);

  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(6); // updated_at key for sorting
  const [sortingColumn, setSortingColumn] = useState("created_at"); // created_at for default sorting
  const [anchorEl, setAnchorEl] = useState(null);
  const openAction = Boolean(anchorEl);
  // store data
  const {
    adminListLoader,
    adminListData,
    adminListDataCount,
    deleteAdminDetails,
    addAdminDetails,
  } = useSelector((state) => state?.admins);
  const { userProfileDetails } = useSelector((state) => state?.userProfile);

  const count = Math.ceil(adminListDataCount / PER_PAGE);
  // const _DATA = usePagination(
  //   adminListData?.data ? adminListData?.data : [],
  //   PER_PAGE,
  //   page,
  //   setPage
  // );
  const _DATA = adminListData?.data ? adminListData?.data : [];

  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const handlePageChange = (e, p) => {
    let prefix = "admin";
    if (params?.adminID) {
      prefix = `admin/${params?.adminID}`;
    }

    setPage(p);
    // _DATA.jump(p);
    // staticData.jump(p);
    let url;
    const direction = directionFlow
      ? `&sorting_order=desc`
      : `&sorting_order=asc`;
    const column = sortingColumn ? `&order_by_column=${sortingColumn}` : "";
    if (selectedValue) {
      url = `${prefix}?limit=${PER_PAGE}&offset=${
        (p - 1) * PER_PAGE
      }&type=${selectedKey}&value=${encodeURIComponent(
        selectedValue
      )}${direction}${column}`;
    } else {
      url = `admin?limit=${PER_PAGE}&offset=${
        (p - 1) * PER_PAGE
      }${direction}${column}`;
    }
    // staticData.jump(p);
    dispatch(
      adminAction.fetchAdminListData({
        url: url,
        data: {},
        token: true,
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  // handle open delete modal
  const handleDeleteButton = (selectedItem) => {
    setIsDeleteAdminModalOpen(true);
    // setSelectedItem(selectedItem);
  };

  const onSuccess = () => {
    if (
      adminListData?.data?.length === 1 &&
      adminListDataCount > PER_PAGE &&
      isDeleteAdminModalOpen
    ) {
      dispatch(
        adminAction.fetchAdminListData({
          url: getAdminFilterUrl(page - 1),
          data: {},
          token: true,
        })
      );
      setPage(page - 1);
    } else {
      dispatch(
        adminAction.fetchAdminListData({
          url: getAdminFilterUrl(page),
          data: {},
          token: true,
        })
      );
    }
  };

  //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteAdminModalOpen(false);
    setSelectedItem(null);
    dispatch(adminAction.setDeletedAdminDetails({}));
  };

  // dispatch action to delete admins
  const handleDeleteAdmin = () => {
    console.log("selected admin-->", selectedItem);
    if (selectedItem && selectedItem?.county_admin) {
      dispatch(
        adminAction.deleteAdminDetails({
          url: `admin/county/delete/user/${selectedItem?.county_admin_id}`,
          data: {},
          token: true,
          type: "post",
        })
      );
    } else {
      dispatch(
        adminAction.deleteAdminDetails({
          url: `admin/${selectedItem?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle filter property in admins
  const handleFilterAdminList = () => {
    if (selectedValue.length > 0 || selectedDateRange) {
      setPage(1);
      dispatch(
        adminAction.fetchAdminListData({
          url: getAdminFilterUrl(1),
          data: {},
          token: true,
        })
      );
      setIsFilterApiCalled(true);
    } else {
      return;
    }
  };

  // handle reset function in admins
  const resetFilterOption = () => {
    setSelectedKey("all");
    setSelectedValue("");
    setSelectedDateRange(null);
    if (isFilterApiCalled) {
      dispatch(
        adminAction.fetchAdminListData({
          url: `admin?limit=${PER_PAGE}&offset=0`,
          data: {},
          token: true,
        })
      );
      setIsFilterApiCalled(false);
      setDirectionFlow(true);
      setSelectedColumn(5);
    }
  };

  // for sorting
  const handleSorting = (column) => {
    setSortingColumn(column);
    setDirectionFlow(!directionFlow);
  };

  const getAdminFilterUrl = useCallback(
    (p) => {
      let prefix = "admin";
      if (params?.adminID) {
        prefix = `admin/${params?.adminID}`;
      }
      let url = `${prefix}?limit=${PER_PAGE}&offset=${(p - 1) * PER_PAGE}`;
      const key =
        selectedValue || selectedDateRange ? `&type=${selectedKey}` : "";
      const value = selectedValue
        ? `&value=${encodeURIComponent(selectedValue)}`
        : "";
      const selectedDateRangePayload = selectedDateRange
        ? `&from_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) ||
            ""
          } &to_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
          }`
        : "";
      const direction = directionFlow
        ? `&sorting_order=desc`
        : `&sorting_order=asc`;
      const column = sortingColumn ? `&order_by_column=${sortingColumn}` : "";

      const finalUrl = `${url}${key}${value}${selectedDateRangePayload}${direction}${column}`;

      return finalUrl;
    },
    [
      directionFlow,
      params.adminID,
      selectedDateRange,
      selectedKey,
      selectedValue,
      sortingColumn,
    ]
  );

  const handleCountyChange = (e, newValue, setFieldValue) => {
    const selectedCounty = registeredCountyListData?.data.find(
      (county) => county.county_domain_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setSelectedValue(selectedCounty?.id?.toString());
    }
  };

  //Open Menu items
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(
      adminAction.fetchAdminListData({
        url: getAdminFilterUrl(page),
        data: {},
        token: true,
      })
    );
  }, [sortingColumn, directionFlow, dispatch, page]);

  // const getAdminListDetails = useCallback(() => {
  //   // dispatch();
  //   dispatch(
  //     adminAction.fetchAdminListData({
  //       url: `admin`,
  //       data: {},
  //       token: true,
  //     })
  //   );
  // }, [dispatch]);

  // useEffect(() => {
  //   getAdminListDetails();
  // }, [getAdminListDetails]);
  const super_admin = userProfileDetails?.data?.role?.includes(ROLES.admin);
  return (
    <>
      {adminListData && adminListData?.success ? (
        <>
          {/* Filtering Component Section */}
          {/* uncomment below */}
          <Tooltip title="Filter options" placement="right-start">
            <Box
              id="filter"
              onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
            >
              <h3 className="filter-text">Filter your results</h3>
              <ArrowDropDownIcon
                className="filter-arrow"
                sx={{ ms: 1, fontSize: "20px" }}
              />
            </Box>
          </Tooltip>
          {/* Filtering Options */}
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "auto",
              mb: 1.5,
            }}
          >
            {toggleFilterOptions && (
              <>
                {/* for mobile view */}
                <Box className="d-block d-md-none">
                  <Grid
                    container
                    className="bg-white d-flex justify-content-around align-items-center px-4"
                    spacing={2}
                    pb={2}
                    pt={2}
                  >
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Typography className="search-text">Search By</Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          value={selectedKey}
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedValue("");
                            setSelectedDateRange(null);
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {columns.map(
                            (cell) =>
                              cell.id !== "no." &&
                              cell.id !== "action" && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.id !== "company_name"
                                    ? cell.label
                                    : "Company Name"}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>

                    {selectedKey !== "created_at" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <FormControl
                          className="filter-search w-100"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {(selectedKey === "all" ||
                      selectedKey === "created_at") && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="w-100">
                        <Button
                          type="submit"
                          onClick={handleFilterAdminList}
                          sx={mobileFilterResultStyle}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        type="reset"
                        onClick={() => {
                          resetFilterOption();
                        }}
                        sx={mobileFilterResetBtnStyle}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* for desktop view */}
                <Card id="filter-options-card" className="d-none d-md-block">
                  <Box className="filter-options-box">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        onChange={(event) => {
                          setSelectedKey(event.target.value);
                          setSelectedValue("");
                          setSelectedDateRange(null);
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem key={"county"} value={"county"}>
                          County
                        </MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.id !== "no." &&
                            cell.id !== "action" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.id !== "company_name"
                                  ? cell.label
                                  : "Company Name"}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>

                    {selectedKey !== "created_at" &&
                      selectedKey !== "county" && (
                        <FormControl
                          className="filter-search"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </FormControl>
                      )}

                    {(selectedKey === "all" ||
                      selectedKey === "created_at") && (
                      <FormControl className="filter-search">
                        <CustomDateRangePicker
                          rangeValue={selectedDateRange}
                          onChange={(values) => {
                            setSelectedDateRange(values);
                          }}
                          onClear={() => {
                            setSelectedDateRange(null);
                          }}
                        />
                      </FormControl>
                    )}

                    {selectedKey === "county" && (
                      <FormControl className="filter-select-one">
                        {/* <TextField
                          id="outlined-select-currency"
                          select
                          label="County List"
                          value={selectedValue}
                          onChange={(e) =>
                            setSelectedValue(e.target.value.toString())
                          }
                        >
                          {registeredCountyListData?.data?.map(
                            (county, key) => (
                              <MenuItem value={county?.id}>
                                {county?.county_domain_name}
                              </MenuItem>
                            )
                          )}
                        </TextField> */}
                        <Autocomplete
                          id="asynchronous-demo"
                          disableClearable
                          options={registeredCountyListData?.data.map(
                            (option) => option?.county_domain_name
                          )}
                          onChange={handleCountyChange}
                          renderInput={(params) => (
                            <TextField {...params} label="County List" />
                          )}
                        />
                      </FormControl>
                    )}

                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterAdminList}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetFilterOption}
                    >
                      Reset
                    </Button>
                  </Box>
                </Card>
              </>
            )}
          </Box>
          {/* admin Listing Table */}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <TableHeaderForSorting
                    columns={columns}
                    handleSorting={handleSorting}
                    directionFlow={directionFlow}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                  />
                  <TableBody>
                    {_DATA?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.address)}
                          tabIndex={-1}
                          key={row?.id}
                        >
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            className="text-capitalize"
                          >{`${row?.first_name} ${row?.last_name}`}</TableCell>
                          <TableCell align="left">{row?.email}</TableCell>
                          <TableCell align="left" className="text-capitalize">
                            {row?.company_name
                              ? row?.company_name
                              : row?.county_name}
                          </TableCell>
                          <TableCell align="left" className="text-capitalize">
                            {row?.county_admin_id ? "County" : "Internal"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.created_at
                              ? getLocalFormatedDate(row?.created_at)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={`${
                              !super_admin &&
                              row?.county_admin_id &&
                              "cursor-disable"
                            }`}
                          >
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMenuClick(event);
                                setSelectedItem(row);
                              }}
                              disabled={
                                super_admin ? false : row?.county_admin_id
                              }
                              size="small"
                              className="settings-button"
                              aria-controls={
                                openAction ? "settings-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openAction ? "true" : undefined}
                            >
                              <SettingsIconSVG
                                color={
                                  !super_admin && row?.county_admin_id
                                    ? "gray"
                                    : "#15BE53"
                                }
                              />
                            </IconButton>
                            {/* <Stack direction="row">
                              <Tooltip
                                title="Edit"
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    color: "#0395FF",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                  }}
                                  disabled={
                                    !userProfileDetails?.data?.role?.includes(
                                      ROLES.admin
                                    )
                                  }
                                  onClick={() => {
                                    dispatch(
                                      adminAction.clearAddAdminDetailsData()
                                    );
                                    handleEditButton(row);
                                  }}
                                >
                                  <ModeOutlinedIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Delete"
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    color: "#FC2125",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                  }}
                                  disabled={
                                    !userProfileDetails?.data?.role?.includes(
                                      ROLES.admin
                                    )
                                  }
                                  onClick={() => {
                                    dispatch(
                                      adminAction.clearAddAdminDetailsData()
                                    );
                                    handleDeleteButton(row);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!adminListData?.data?.length > 0 && !adminListLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
            {adminListDataCount > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Typography className="text-danger text-center">
          {!adminListData?.success
            ? adminListData?.data?.message?.length > 0
              ? adminListData?.data?.message
              : adminListData?.error?.length > 0
              ? adminListData?.error
              : ""
            : ""}
        </Typography>
      )}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={openAction}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            navigate("/admin-dashboard?subadmin/" + selectedItem?.id);
          }}
        >
          <Box className="setting-menu-box">
            <RemoveRedEyeOutlinedIcon />
            <Typography className="setting-menu-text">View Users</Typography>
          </Box>
        </MenuItem>
        {userProfileDetails?.data?.role?.includes(ROLES.admin) && (
          <>
            <MenuItem
              className="setting-menu-item"
              onClick={() => {
                dispatch(adminAction.clearAddAdminDetailsData());
                handleEditButton(selectedItem);
              }}
            >
              <Box className="setting-menu-box">
                <ModeOutlinedIcon />

                <Typography className="setting-menu-text">Edit</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              className="setting-menu-item"
              onClick={() => {
                dispatch(adminAction.clearAddAdminDetailsData());
                handleDeleteButton();
              }}
            >
              <Box className="setting-menu-box">
                <DeleteIcon />
                <Typography className="setting-menu-text">Delete</Typography>
              </Box>
            </MenuItem>
          </>
        )}
      </Menu>
      <DeleteModal
        open={isDeleteAdminModalOpen}
        title="Delete Admin"
        item="Admin"
        deleteLoader={adminListLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeleteAdmin}
        fetchedData={deleteAdminDetails}
        isDeleteSuccess={deleteAdminDetails?.success}
        successMessage={deleteAdminDetails?.message}
        errorMessage={deleteAdminDetails?.data?.message}
        callListingAPI={() => {
          onSuccess();
        }}
        footer={false}
      />
      {/* {getObjLength(addAdminDetails) ? (
        addAdminDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleClose();
              dispatch(adminAction.clearAddAdminDetailsData());
            }}
            mesage={addAdminDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              setAlertMsg(false);
              dispatch(adminAction.clearAddAdminDetailsData());
            }}
            mesage={addAdminDetails?.data?.message}
            footer={false}
          />
        )
      ) : null} */}
      <Loader open={adminListLoader} />
    </>
  );
}

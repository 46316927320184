import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "./AlertDialog";

export const LoaderWithTimeout = ({
  open,
  message = false,
  timeout = 30000,
}) => {
  const [showTimeout, setShowTimeout] = useState(false);

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setShowTimeout(true);
      }, timeout);
    }

    return () => {
      clearTimeout(timer);
      setShowTimeout(false);
    };
  }, [open, timeout]);

  if (!open) return null;

  const content = showTimeout ? (
    <AlertDialog
      type="Timeout"
      openPopup={true}
      onClose={true}
      onCloseFun={() => {
        window.location.reload();
      }}
      mesage={
        <>
          <div>
            <p>
              We encountered an issue loading the map. You can reload the page
              to try again or check your network connection.
            </p>
          </div>
        </>
      }
      footer={false}
    />
  ) : (
    <Backdrop sx={{ color: "#fff", zIndex: 13000002 }} open={open}>
      <CircularProgress color="inherit" />
      &nbsp;&nbsp;&nbsp;{message || "Please wait.."}
    </Backdrop>
  );

  return ReactDOM.createPortal(content, document.body);
};

import { useRef } from "react";
import { useCallback } from "react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";
import List from "@mui/material/List";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useTheme, useMediaQuery } from "@mui/material";

import ReferralLink from "./ReferralLink";
import PaymentFailed from "./PaymentFailed";
import { Loader } from "../../common/loader";
import CardCRUD from "../../common/CardCRUD";
import { DashboadrForm } from "./dashboadrForm";
import BiometricAndSign from "./BiometricAndSign";
import SubUserInfoModal from "./SubUserInfoModal";
import AlertDialog from "../../common/AlertDialog";
import DeleteModal from "../../common/DeleteModal";
import EditBeneficiaryModal from "./EditBeneficiaryModal";
import StannupInfoModal from "../../common/StannupInfoModal";
import SupportEmailLink from "../../common/SupportEmailLink";
import PropertiesListing from "./StannupVerification/PropertiesListing";
import ProfileDP from "../../../assets/dashboard/Dummy_Profile_photo.png";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import UploadProfilePictureModal from "../../common/UploadProfilePictureModal";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import {
  AddressVerificationColumns,
  ROLES,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";
import {
  clearLocalStoarge,
  decryptPayload,
  encryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../helpers/utils";

import "../../../styles/dashboard.scss";
import useClearLocalStorage from "../../hooks/useClearLocalStorage";

const styles = {
  "&.MuiButton-outlined": {
    background: "#FFFFFF",
    border: "1px solid #15BE53",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#15BE53",
    padding: "11px",
    minWidth: "auto",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const location = useLocation();
  const params = location?.search?.split("=")?.[1];
  const { clearLocalStorageValues } = useClearLocalStorage();

  console.log(params, "location", location);
  const [open, setOpen] = useState(false);
  const [openStanupPopup, setOpenStanupPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isEditBeneficiaryModalOpen, setIsEditBeneficiaryModalOpen] =
    useState(false);
  const [isDeleteBeneficiaryModalOpen, setIsDeleteBeneficiaryModalOpen] =
    useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [isEditProfilePictureModalOpen, setIsEditProfilePictureModalOpen] =
    useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);
  const [isSignPopup, setIsSignPopup] = useState(false);
  const [isSubUserPopupDisplay, setIsSubUserPopupDisplay] = useState(false);

  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));

  const {
    cardDetails,
    userProfileDetails,
    addBeneficiaryDetails,
    beneficiaryList,
    beneficiaryLoader,
    StannupEmailDetails,
    addressLoader,
    biometric_signLoader,
    referralLoader,
    editedUserProfileDetails: editedData,
    loggedInEmail,
    editProfileLoader,
    loginAsSpouse: isSecondaryUser,
    profileLoader,
    paymnetDoneDetails,
    isPaymentPending,
    paymentLoader,
    cardLoader,
    isResetPasswordRequired,
  } = useSelector((state) => state?.userProfile);
  const { stateList: stateData } = useSelector((state) => state?.signUpUser);

  // const subuserLogin = parseInt(localStorage.getItem("subuserLogin"));
  const subuserLogin = 3; //temporary hide the subuser info popup

  // handle upload profile picture
  const handleUploadProfilePicture = (jpegFile) => {
    const formData = new FormData();

    formData.append("first_name", userProfileDetails?.data?.first_name);
    formData.append("last_name", userProfileDetails?.data?.last_name);
    formData.append("phone_number", userProfileDetails?.data?.phone_number);
    formData.append(
      "address",
      userProfileDetails?.data?.address?.address || ""
    );
    formData.append("city", userProfileDetails?.data?.address?.city || "");
    formData.append("state", userProfileDetails?.data?.address?.state || "");
    formData.append("zip", userProfileDetails?.data?.address?.zip || "");
    formData.append("old_password", "");
    formData.append("password", "");
    formData.append("password_confirmation", "");
    formData.append("profile_img", jpegFile);

    dispatch(
      userProfileAction.editUserProfileData({
        url: "profile",
        data: formData,
        token: true,
      })
    );
  };

  // to get beneficiary data of logged in user
  const getBeneficiaryData = useCallback(() => {
    dispatch(
      userProfileAction.fetchBeneficiaryList({
        url: "customer/beneficiaries",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    localStorage.removeItem("setLoginUserToken");
    if (!isSecondaryUser) {
      handleToggle();
      console.log("close... Dashboard");
      userProfileAction.clearUserProfileData();
      dispatch(userSigupAction.getState({ url: "states", data: {} }));
      dispatch(
        userProfileAction.userCardDetails({
          url: "customer/card/list",
          data: {},
          token: true,
        })
      );
      // dispatch(
      //   userProfileAction.getStannupEmailData({
      //     url: "customer/address",
      //     data: {},
      //     token: true,
      //   })
      // );
      dispatch(
        userProfileAction.getBiometricData({
          url: "customer/get-biometric/face",
          data: {},
          token: true,
        })
      );
      dispatch(
        userProfileAction.getSignatureData({
          url: "customer/get-biometric/signature",
          data: {},
          token: true,
        })
      );
      getBeneficiaryData();
    }
    dispatch(
      userProfileAction.getReferralData({
        url: "generate-referral-link",
        data: {},
        token: true,
      })
    );
    dispatch(userSigupAction.clearuserLoginData({}));
    if (
      parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isResetPasswordRequired !== 1
    ) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, getBeneficiaryData, isResetPasswordRequired, isSecondaryUser]);

  useEffect(() => {
    console.log("profile data called==>", userProfileDetails);
    if (cardDetails?.success) {
      handleClose();
    }
  }, [userProfileDetails, cardDetails]);

  // // useeffect to fetch beneficiary details
  // useEffect(() => {
  //   if (!userProfileDetails?.data?.role?.includes(ROLES.secondaryUser)) {
  //     getBeneficiaryData();
  //   }
  // }, [getBeneficiaryData, userProfileDetails]);

  useEffect(() => {
    if (getObjLength(StannupEmailDetails)) {
      dispatch(userProfileAction.paymnetDoneDetails({}));
      setIsPaymentDonePopup(false);
    }
    if (StannupEmailDetails?.success && StannupEmailDetails?.data?.length > 0) {
      if (localStorage.getItem("stanupPopupCount") === "1") {
        setOpenStanupPopup(true);
      }
    }
  }, [StannupEmailDetails]);

  useEffect(() => {
    if (
      (getObjLength(paymnetDoneDetails) && isPaymentPending) ||
      parseInt(localStorage.getItem("paymentPopup")) > 2
    ) {
      //open modal
      setIsPaymentDonePopup(false);
      if (userProfileDetails?.data?.added_equity_property) {
        dispatch(
          userProfileAction.getStannupEmailData({
            url: "customer/address",
            data: {},
            token: true,
          })
        );
      } else {
        // to open subuser info modal
        if (
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.secondaryUser
          )
        )
          localStorage.setItem("userRoleCode", encryptPayload("52"));
      }
    }
    if (paymnetDoneDetails?.data?.properties?.length > 0) {
      setIsPaymentDonePopup(true);
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  const closeSubUserInfoModal = () => {
    setIsSignPopup(true);
    //to open signature popup for subuser account
    localStorage.setItem("userRoleCode", encryptPayload("53"));
    localStorage.setItem("subuserLogin", subuserLogin + 1);
  };

  const handleClose = () => {
    if (userProfileDetails?.success) {
      setEdit(false);
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const clearUserData = () => {
    dispatch(userProfileAction.clearUserProfileData());
  };

  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    reactPixelEventOnlyForCustomer("Signout", { email: loggedInEmail });

    let isIos = false;
    if (JSON.parse(localStorage.getItem("isIos"))) {
      isIos = JSON.parse(localStorage.getItem("isIos"));
    }
    // clearLocalStoarge();
    clearLocalStorageValues();
    localStorage.setItem("ivokeNativeMethod", isIos);

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };

  // open edit benefeciary modal
  const handleEditBeneficiaryModalOpen = (selectedBeneficiary) => {
    setSelectedBeneficiary(selectedBeneficiary);
    setIsEditBeneficiaryModalOpen(true);
  };

  // close edit/delete benefeciary modal
  const handleCloseBeneficiaryModal = () => {
    setIsEditBeneficiaryModalOpen(false);
    setIsDeleteBeneficiaryModalOpen(false);
    setSelectedBeneficiary(null);
  };

  // open delete benefeciary modal
  const handleDeleteBeneficiaryModalOpen = (selectedBeneficiary) => {
    setSelectedBeneficiary(selectedBeneficiary);
    setIsDeleteBeneficiaryModalOpen(true);
  };

  // submit add/edit beneficiary data
  const handleSubmitBeneficiary = (values) => {
    if (selectedBeneficiary && selectedBeneficiary?.id) {
      // return console.log("selectedBeneficiary", selectedBeneficiary);
      dispatch(
        userProfileAction.addBeneficiaryDetails({
          url: `customer/beneficiary/${selectedBeneficiary?.id}`,
          data: { ...values },
          token: true,
        })
      );
    } else {
      dispatch(
        userProfileAction.addBeneficiaryDetails({
          url: "beneficiaries",
          data: {
            user_id: userProfileDetails?.data?.user_id,
            ...values,
            step: 7,
            step_key: "SEND_WELCOME_EMAIL",
          },
        })
      );
    }
  };

  // handle deleting benefeciary
  const handleDeleteBeneficiary = () => {
    if (selectedBeneficiary && selectedBeneficiary?.id) {
      // return console.log("selectedBeneficiary", selectedBeneficiary);
      dispatch(
        userProfileAction.deleteBeneficiaryDetails({
          url: `customer/beneficiary/${selectedBeneficiary?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle edit profile picture button
  const handleEditProfilePicBtn = () => {
    if (formRef?.current) {
      formRef?.current?.resetForm();
    }
    setEdit(false);
    setIsEditProfilePictureModalOpen(true);
  };

  // handle edit profile picture button
  const handleEditProfilePicModalClose = () => {
    setIsEditProfilePictureModalOpen(false);
    setEdit(false);
  };

  // handle back to original user
  const handleLogoutFromSpouse = () => {
    dispatch(userProfileAction.logoutFromSpouse());
  };

  // handle password Reset Required
  const passwordResetRequired = () => {
    localStorage.setItem(
      "isResetPasswordRequired",
      parseInt(isResetPasswordRequired) + 1
    );
    dispatch(userProfileAction.clearResetPasswordRequired());
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  };

  useEffect(() => {
    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.secondaryUser
      )
    ) {
      let data =
        JSON.parse(decryptPayload(localStorage.getItem("subUsersList"))) || [];
      const userExistsIndex = data.findIndex(
        (item) => item.user_id === parseInt(localStorage.getItem("uID"))
      );
      const uid_data = data?.find(
        (userId) => userId?.user_id === parseInt(localStorage.getItem("uID"))
      );
      // console.log(data, "uid_data: ", uid_data);
      if (uid_data) {
        if (userExistsIndex !== -1) {
          if (uid_data?.count < 2) {
            // User exists, update count
            setIsSubUserPopupDisplay(true);
          }
        } else {
          setIsSubUserPopupDisplay(false);
        }
      } else {
        localStorage.removeItem("subUsersList");
      }
    }
  }, []);

  // useEffect(() => {
  //   let value = localStorage.getItem("reloadPage");
  //   if (params === "ios") {
  //     if (params === "ios" || params === "android") {
  //       localStorage.setItem("isMobile", encryptPayload(params));
  //     }
  //     if (!parseInt(value)) {
  //       localStorage.setItem("reloadPage", 1);
  //       setTimeout(() => {
  //         console.log("web page reloaded successfully !");
  //         window.location.reload();
  //       }, 5000);
  //       console.log(value, "ios called==>", params);
  //     }
  //   }
  // }, []);

  return (
    <>
      <Box className="profile-wrapper mb-4">
        {Object.keys(userProfileDetails).length > 0 ? (
          userProfileDetails?.success ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography variant="h6" className="title">
                  My Profile
                </Typography>
                <Stack direction="row" spacing={2}>
                  {/* {isSecondaryUser && (
                    <Button
                      variant="contained"
                      className="signoutBtn d-none d-md-block"
                      sx={{
                        padding: "6px 20px !important",
                        fontSize: "12px !important",
                      }}
                      onClick={handleLogoutFromSpouse}
                    >
                      Back to {userProfileDetails?.data?.first_name}{" "}
                      {userProfileDetails?.data?.last_name}
                    </Button>
                  )}
                  {!isSecondaryUser && ( */}
                  <Button
                    variant="contained"
                    className="signoutBtn d-none d-md-block"
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Button>
                  {/* )} */}
                </Stack>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Paper
                    elevation={0}
                    className="personal-info"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title" my={1.6}>
                        Personal information
                      </Typography>
                      {/* {!isSecondaryUser && ( */}
                      {!edit ? (
                        <>
                          <Button
                            variant="outlined"
                            className="d-none d-md-block EditBtn"
                            sx={styles}
                            onClick={() => setEdit((edit) => !edit)}
                            // disabled={isSecondaryUser}
                          >
                            Edit
                          </Button>
                          {/* )} */}

                          <Button
                            variant="outlined"
                            className="d-block d-md-none EditBtn p-2"
                            sx={styles}
                            onClick={() => setEdit(!edit)}
                            // disabled={isSecondaryUser}
                          >
                            <ModeEditOutlineOutlinedIcon fontSize="small" />
                          </Button>
                        </>
                      ) : null}
                    </Box>
                    <Box className="d-flex align-items-center">
                      <Box className="profile-photo-box">
                        <img
                          src={
                            userProfileDetails?.data?.profile_img
                              ? userProfileDetails?.data?.profile_img
                              : ProfileDP
                          }
                          alt="Profile"
                          className="profilePic"
                        />
                        <IconButton
                          className="profile-photo-edit-btn"
                          onClick={handleEditProfilePicBtn}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className="form" sx={{ mt: 5 }}>
                      {userProfileDetails?.success && (
                        <DashboadrForm
                          userProfileDetails={userProfileDetails}
                          stateData={stateData}
                          edit={edit}
                          setEdit={setEdit}
                          formRef={formRef}
                        />
                      )}
                    </Box>
                  </Paper>

                  {/* payment information */}
                  {!isSecondaryUser && (
                    <>
                      <CardCRUD isSecondaryUser={false} />
                    </>
                  )}

                  {!isSecondaryUser &&
                    Object.keys(StannupEmailDetails).length > 0 &&
                    (StannupEmailDetails?.data).length > 0 && (
                      <Paper
                        elevation={0}
                        className="payment-info"
                        sx={Paperstyles}
                      >
                        <Box
                          className="d-flex align-items-center justify-content-between"
                          sx={{ mb: 3 }}
                        >
                          <Typography variant="h6" className="card-title">
                            Address Verification
                          </Typography>
                        </Box>
                        <Box>
                          <PropertiesListing
                            columns={AddressVerificationColumns}
                            rowsData={StannupEmailDetails?.data}
                          />
                        </Box>
                      </Paper>
                    )}
                </Grid>
                <Grid
                  className="pt-1 pt-lg-3"
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  {/* beneficiary details */}
                  {!isSecondaryUser && (
                    <Paper
                      elevation={0}
                      className="trusted-contacts"
                      sx={Paperstyles}
                    >
                      <Box
                        className="d-flex align-items-center justify-content-between"
                        sx={{ mb: 1 }}
                      >
                        <Typography variant="h6" className="card-title">
                          Beneficiary
                        </Typography>
                        {/* {!isSecondaryUser && ( */}
                        <Button
                          variant="outlined"
                          className="d-none d-md-block"
                          sx={styles}
                          disabled={
                            beneficiaryList?.data?.length === 2 ||
                            isSecondaryUser
                          }
                          onClick={() => {
                            setIsEditBeneficiaryModalOpen(true);
                          }}
                        >
                          Add
                        </Button>
                        {/* )} */}
                        <Button
                          variant="outlined"
                          className="d-block d-md-none EditBtn p-2"
                          sx={styles}
                          disabled={
                            beneficiaryList?.data?.length === 2 ||
                            isSecondaryUser
                          }
                          onClick={() => {
                            setIsEditBeneficiaryModalOpen(true);
                          }}
                        >
                          <AddIcon fontSize="small" />
                        </Button>
                      </Box>
                      <Typography
                        variant="body1"
                        className="w-75 caption mb-3 mb-lg-4"
                      >
                        {getObjLength(beneficiaryList?.data) ? (
                          "These are the Beneficiary."
                        ) : (
                          <b>Please add your Beneficiaries</b>
                        )}
                      </Typography>
                      {beneficiaryList?.success &&
                        beneficiaryList?.data?.map((beneficiaryData, index) => (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              position="relative"
                            >
                              <Box className="contacts d-flex flex-column">
                                <Typography
                                  variant="body1"
                                  className="person-name"
                                >
                                  {`${beneficiaryData?.first_name} ${beneficiaryData?.last_name}`}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="mb-1 person-relation"
                                >
                                  {beneficiaryData?.relation}
                                </Typography>
                              </Box>
                              <Stack
                                direction="row"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Tooltip
                                  title="Edit"
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    sx={{
                                      color: "#0395FF",
                                      fontSize: "1.4rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleEditBeneficiaryModalOpen(
                                        beneficiaryData
                                      )
                                    }
                                    disabled={isSecondaryUser}
                                  >
                                    <ModeOutlinedIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip
                                  title="Delete"
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    sx={{
                                      color: "#FC2125",
                                      fontSize: "1.4rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        userProfileAction.clearBeneficiaryData()
                                      );
                                      handleDeleteBeneficiaryModalOpen(
                                        beneficiaryData
                                      );
                                    }}
                                    disabled={isSecondaryUser}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Stack>
                            {index < beneficiaryList?.data?.length - 1 && (
                              <Divider className="separator my-3" />
                            )}
                          </>
                        ))}
                    </Paper>
                  )}

                  {/* Trusted contacts */}
                  {userProfileDetails?.data?.role?.includes(ROLES.customer) &&
                  userProfileDetails?.data?.default_professional_id ? (
                    <Paper
                      elevation={0}
                      className="help-contact"
                      sx={Paperstyles}
                    >
                      <Box
                        className="d-flex align-items-center justify-content-between"
                        sx={{ mb: 3 }}
                      >
                        <Typography variant="h6" className="card-title">
                          Trusted Contacts
                        </Typography>
                      </Box>
                      <List
                        className="supportContact"
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                      >
                        <>
                          <ListItem
                            disablePadding
                            className="align-items-start"
                          >
                            <ListItemText className="mb-0 label">
                              Name:
                            </ListItemText>
                            <ListItemText className="mb-0 content">
                              {`${userProfileDetails?.data?.default_professional_id?.first_name} ${userProfileDetails?.data?.default_professional_id?.last_name}`}
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className="align-items-start"
                          >
                            <ListItemText className="mb-0 label">
                              Company:
                            </ListItemText>
                            <ListItemText className="mb-0 content">
                              {`${
                                userProfileDetails?.data
                                  ?.default_professional_id?.company_name || "-"
                              }`}
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className="align-items-start"
                          >
                            <ListItemText className="mb-0 label">
                              Email:
                            </ListItemText>
                            <ListItemText className="mb-0 content">
                              <SupportEmailLink
                                email={
                                  userProfileDetails?.data
                                    ?.default_professional_id?.email
                                }
                                text={
                                  userProfileDetails?.data
                                    ?.default_professional_id?.email
                                }
                              />
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className="align-items-start"
                          >
                            <ListItemText className="mb-0 label">
                              Type:
                            </ListItemText>
                            <ListItemText className="mb-0 content">
                              {userProfileDetails?.data?.default_professional_id
                                ?.type
                                ? userProfileDetails?.data
                                    ?.default_professional_id?.type
                                : "-"}
                            </ListItemText>
                          </ListItem>
                        </>
                      </List>
                    </Paper>
                  ) : null}

                  {/* Help and contacts */}
                  <Paper
                    elevation={0}
                    className="help-contact"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Help and contact
                      </Typography>
                    </Box>
                    <List
                      className="supportContact"
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                    >
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Support:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={"Click here to contact us  "}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Email:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={SUPPORT_EMAIL}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          By phone:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <div className="d-flex flex-column">
                            <Callto phone={`${SUPPORT_PHONE}`}>
                              {SUPPORT_PHONE_FORMAT}
                            </Callto>
                            <p className="mb-0">
                              MON-FRI: <span>8:00AM - 6:00PM</span>
                            </p>
                            <p className="mb-0">
                              SAT-SUN: <span>9:00AM - 5:00PM</span>
                            </p>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Paper>

                  {!isSecondaryUser && (
                    <>
                      {/* signature and biomatric */}
                      <BiometricAndSign
                        Paperstyles={Paperstyles}
                        isSignPopup={isSignPopup}
                        setIsSignPopup={setIsSignPopup}
                      />
                    </>
                  )}
                  {/* referal link */}
                  <ReferralLink Paperstyles={Paperstyles} />
                </Grid>
              </Grid>
              <Box sx={isPadding ? { marginBottom: "160px" } : { my: 2 }}>
                {/* {isSecondaryUser && (
                  <Button
                    variant="contained"
                    className="signoutBtn d-block d-md-none w-100 mb-3"
                    sx={{
                      padding: "6px 20px !important",
                      fontSize: "12px !important",
                    }}
                    onClick={handleLogoutFromSpouse}
                  >
                    Back to {userProfileDetails?.data?.first_name}{" "}
                    {userProfileDetails?.data?.last_name}
                  </Button>
                )}
                {!isSecondaryUser && ( */}
                <Button
                  variant="contained"
                  className="signoutBtn d-block d-md-none w-100"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
                {/* )} */}
              </Box>
            </>
          ) : (
            <Box className="text-center">
              <Button
                className="text-danger text-capitalize"
                onClick={clearUserData}
              >
                {userProfileDetails?.data?.message}
                <Link to={"/login"} className="bold text-decoration-none">
                  &nbsp;Click here to Login again.
                </Link>
              </Button>
            </Box>
          )
        ) : (
          <Loader open={true} />
        )}
      </Box>

      {/* {!beneficiaryLoader && ( */}
      {isEditBeneficiaryModalOpen ? (
        <EditBeneficiaryModal
          open={isEditBeneficiaryModalOpen}
          // open={true}
          selectedItem={selectedBeneficiary}
          handleClose={handleCloseBeneficiaryModal}
          onSubmit={handleSubmitBeneficiary}
        />
      ) : null}

      {isDeleteBeneficiaryModalOpen ? (
        <DeleteModal
          open={isDeleteBeneficiaryModalOpen}
          title="Delete Beneficiary"
          item="Beneficiary"
          deleteLoader={beneficiaryLoader}
          handleClose={handleCloseBeneficiaryModal}
          handleDeleteBtn={handleDeleteBeneficiary}
          fetchedData={addBeneficiaryDetails}
          isDeleteSuccess={addBeneficiaryDetails?.success}
          successMessage={addBeneficiaryDetails?.message}
          errorMessage={addBeneficiaryDetails?.data?.message}
        />
      ) : null}
      {/* )} */}

      {/* stannup info modal */}
      {openStanupPopup ? (
        <StannupInfoModal
          openStanupPopup={openStanupPopup}
          setOpenStanupPopup={() => {
            setOpenStanupPopup(false);
            // to open subuser info modal
            if (
              decryptPayload(localStorage.getItem("roles"))?.includes(
                ROLES.secondaryUser
              )
            ) {
              localStorage.setItem("userRoleCode", encryptPayload("52"));
            }
          }}
        />
      ) : null}

      {/* upload profile picture modal */}
      {isEditProfilePictureModalOpen ? (
        <UploadProfilePictureModal
          open={isEditProfilePictureModalOpen}
          handleClose={handleEditProfilePicModalClose}
          handleUploadProfilePicture={handleUploadProfilePicture}
          loader={editProfileLoader}
        />
      ) : null}

      {/*Profile Edited  Alert messages*/}
      {getObjLength(editedData) ? (
        editedData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.fetcheditedUserData({}));
              handleEditProfilePicModalClose();
              dispatch(
                userProfileAction.userProfileData({
                  url: "profile",
                  data: {},
                  token: true,
                })
              );
            }}
            mesage={editedData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() =>
              dispatch(userProfileAction.fetcheditedUserData({}))
            }
            mesage={editedData?.data?.message}
          />
        )
      ) : null}

      {/* Paymnet failed Popup */}
      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isPaymentDonePopup ? (
        <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
      ) : null}

      {/* Reset password popup  */}
      {isResetPasswordRequired === 1 ? (
        <AlertDialog
          type="Attention"
          openPopup={isResetPasswordRequired}
          onClose={true}
          onCloseFun={passwordResetRequired}
          mesage={
            <p className="fw-400 mt-3">
              Please change auto generated password for the better security of
              your account.
            </p>
          }
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={passwordResetRequired}
            >
              Ok, All Good
            </Button>
          }
        />
      ) : null}

      {/* Subuser Info popup */}
      {isSubUserPopupDisplay &&
        subuserLogin < 2 &&
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.secondaryUser
        ) &&
        parseInt(decryptPayload(localStorage.getItem("userRoleCode"))) ===
          52 && (
          <AlertDialog
            width={"md"}
            type="Info"
            title="Explore Linked Account Details with Ease"
            openPopup={true}
            onClose={true}
            onCloseFun={closeSubUserInfoModal}
            mesage={<SubUserInfoModal />}
            button={
              <Button
                className="popup_Btn success_popup_btn"
                onClick={closeSubUserInfoModal}
              >
                ok, All Good
              </Button>
            }
          />
        )}
      <Loader
        open={
          open ||
          beneficiaryLoader ||
          addressLoader ||
          biometric_signLoader ||
          referralLoader ||
          profileLoader ||
          editProfileLoader ||
          paymentLoader ||
          cardLoader
        }
      />
    </>
  );
}
